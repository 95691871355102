import { ReactChildren } from "@/types";
import { IPaperQuantity, ProductListItem, ProductsQuantity, SilverProduct } from "@/types/products";
import { createContext, useContext, useEffect, useState } from "react";
import { useMember } from "../member/MemberContext";
import { PriceType } from "@/types/canvas";
import { useQueue } from "../queue/QueueContext";
import { IPaperSize } from "@/components/modals/products/search/IProductSelectionModal";
import Api from "@/api/Api";
import { extractSilverProducts } from "../products/SearchContext";
import { IProductParams } from "@/components/drawers/components/CardProductParams";
import { useCompany } from "../company/CompanyContext";
import { EntityID } from "@/types/silverstripe";

type IBatch = {
	products: ProductListItem[],
	setProducts: (product: ProductListItem[]) => void,

	cacheProductsModal: ProductListItem[],
	setCacheProductsModal: (products: ProductListItem[]) => void,

	productsQuantity: ProductsQuantity[],
	setProductsQuantity: (product: ProductsQuantity[]) => void,

	selectedProducts: ProductListItem[],
	setSelectedProducts: (product: ProductListItem[]) => void,

	productParams: IProductParams[],
	setProductParams: (prevSelected: IProductParams[]) => void,

	selectedMarkets: IMarketField[],
	setSelectedMarkets: (markets: IMarketField[]) => void,

	listBatch: Item[] | null,
	setListBatch: (listBatch: Item[]) => void,

	listAvailableDates: Item[],
	setListAvailableDates: (date: Item[]) => void,

	batchSelected: Item | null,
	setBatchSelected: (batch: Item | null) => void,

	uniquePriceTypes: PriceType[],
	setUniquePriceTypes: (uniquePriceTypes: PriceType[]) => void,

	selectedDate: Item | null,
	setSelectedDate: (selectedDateOffer: Item | null) => void,

	paperOrientation: IPaperSize,

	templateOrientation: IPaperSize,
	setTemplateOrientation: (orientation: IPaperSize) => void,

	determinePriceType: (product: any) => PriceType,
	determinePriceTypeAllowed: (product: any) => PriceType[],
	resetQuantityInputs: () => void,
	handleCheckProductQuantity: (product: ProductListItem) => boolean,
	getBatchList: () => Promise<void>,
	transferQuantities: (isReverse?: boolean) => void;

	getBatchProducts: (batchId: number, modalManager?: boolean) => Promise<IProductParams[]>,

	productsModalManager: ProductListItem[],
	setProductsModalManager: (product: ProductListItem[]) => void;

	isLoading: boolean,
	setIsLoading: (value: boolean) => void;

	isReuseQtd: boolean,
	setReuseQtd: (value: boolean) => void;

	productSearch: ProductListItem[],
	setProductSearch: (product: ProductListItem[]) => void;

	adminMode: AdminMode,
	setAdminMode: (mode: AdminMode) => void;

	adminMarket: MarketsAdmin | null,
	setAdminMarket: (value: any) => void;

	marketsCompany: MarketsAdmin[],

	hasRole: boolean | null,

	batchScenario: string | null,

	today: string,
	tomorrow: string
}

type Item = {
	label: string,
	data?: any
}

type AdminMode = "manage" | "print" | "";

type MarketsAdmin = { id: EntityID, name: string, storeID: number }

export type IMarketField = { label: string, id: number }
const BatchProducts = createContext<IBatch>({} as IBatch);

export const BathProductsProvider = ({ children }: { children: ReactChildren }) => {
	const { member } = useMember();
	const { company } = useCompany();
	const { allowedOrientations } = useQueue();
	const today = new Date().toISOString().slice(0, 10);
	const tomorrow = new Date(Date.now() + 86400000).toISOString().slice(0, 10);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isReuseQtd, setReuseQtd] = useState<boolean>(true);

	// State of Products
	const [products, setProducts] = useState<ProductListItem[]>([]);
	const [productsModalManager, setProductsModalManager] = useState<ProductListItem[]>([]);
	const [productParams, setProductParams] = useState<IProductParams[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<ProductListItem[]>([]);
	const [productsQuantity, setProductsQuantity] = useState<ProductsQuantity[]>([]);
	const [productSearch, setProductSearch] = useState<ProductListItem[]>([]);

	// State of Filters
	const [listBatch, setListBatch] = useState<Item[]>([]);
	const [batchSelected, setBatchSelected] = useState<Item | null>(null);

	const [listAvailableDates, setListAvailableDates] = useState<Item[]>([]);
	const [selectedDate, setSelectedDate] = useState<Item | null>(null);

	// State of create Batch
	const [selectedMarkets, setSelectedMarkets] = useState<IMarketField[]>([]);
	const [uniquePriceTypes, setUniquePriceTypes] = useState<PriceType[]>([]);

	// state of batch Modal
	const [cacheProductsModal, setCacheProductsModal] = useState<ProductListItem[]>([]);

	const paperOrientation = Object.entries(allowedOrientations)
		.find(([_, value]) => Boolean(value) === true)?.[0] as IPaperSize;

	// Template orientation
	const [templateOrientation, setTemplateOrientation] = useState<IPaperSize>(paperOrientation);

	// Admin Mode Options
	const [adminMode, setAdminMode] = useState<AdminMode>((""));

	// Markets Admin Control
	const [adminMarket, setAdminMarket] = useState<MarketsAdmin | null>(null);

	const marketsCompany: MarketsAdmin[] = !company ? []
		: company.markets.map((market) => ({ id: market.id, name: market.name, storeID: market.storeID }));

	useEffect(() => {
		setAdminMode(hasRole ? 'manage' : 'print')

		const userMarket = marketsCompany.find(market => market.storeID === member.marketID);
		userMarket && setAdminMarket(userMarket);
	}, [member.marketID, company]);

	const resetQuantityInputs = () => {
		const inputs = document.querySelectorAll('input.qtd');
		inputs.forEach(input => {
			const inputElement = input as HTMLInputElement;
			inputElement.value = '0';
		});
	}

	const hasRole = member.role && ["manager", "marketing", "admin"].includes(member.role);

	const batchScenario = company?.batchScenario || null;

	const determinePriceType = (product: any): PriceType => {
		return determinePriceTypeAllowed(product).reverse()[0];
	}

	const determinePriceTypeAllowed = (product: any): PriceType[] => {
		const type: PriceType[] = [];
		if (product.sPrice && !["0,00", "0.00"].includes(product.sPrice)) {
			type.push("simple");
		}
		if (product.oPrice && !["0,00", "0.00"].includes(product.oPrice)) {
			type.push("offer");
		}
		if (product.cPrice && !["0,00", "0.00"].includes(product.cPrice)) {
			type.push("club");
		}
		if (product.ePrice && !["0,00", "0.00"].includes(product.ePrice)) {
			type.push("extra");
		}
		return type;
	}

	// Função para transferir valores de vertical e p21 para horizontal e p30, e vice-versa
	const transferQuantities = (isReverse: boolean = false) => {
		setProductsQuantity(productsQuantity.map(product => {
			if (isReverse) {
				product.vertical = { ...product.horizontal };
				product.p21 = { ...product.p30 };
				product.horizontal = zeroBulkQtd();
				product.p30 = zeroBulkQtd();
			} else {
				product.horizontal = { ...product.vertical }
				product.p30 = { ...product.p21 }
				product.vertical = zeroBulkQtd()
				product.p21 = zeroBulkQtd()
			}
			return product;
		}));
	}

	function zeroBulkQtd(): IPaperQuantity {
		return {
			_2A3: 0,
			A3: 0,
			_2A4: 0,
			A4: 0,
			A5: 0,
			A6: 0,
			A7: 0
		};
	}

	const handleCheckProductQuantity = (product: ProductListItem) => {
		const productsQuantityCopy = [...productsQuantity];
		const productQuantity = productsQuantityCopy.find(quantity => String(quantity.ean) === String(product.ean));
		return !productQuantity ||
			Object.values(productQuantity).every(bulkQtd =>
				!bulkQtd || Object.values(bulkQtd).every(value => value === 0)
			);
	}

	const getBatchProducts = async (batchId: number, modalManager: boolean = false) => {
		const api = new Api("bulk", "gl");
		const request = api.request(member, { batchId, storeID: member.marketID });
		const { success, code, message, data } = await api.post(request);

		if (!success && code === "404") {
			console.error(message);
			return [];
		}
		const parseToProductListItem = data.map((prod: SilverProduct) => extractSilverProducts(prod));
		if (modalManager) { setProductsModalManager(parseToProductListItem); return parseToProductListItem; }

		setProducts(parseToProductListItem);

		localStorage.setItem("selectedBatchProducts", JSON.stringify(parseToProductListItem));

		const updatedProductParams = [...productParams];  // Copia os parâmetros existentes

		parseToProductListItem.forEach((product: ProductListItem) => {
			const matchedItem = data.find((item: any) => String(item.Ean) === String(product.ean));

			const newProductParam = {
				productEan: String(product.ean),
				gram: matchedItem?.wantUseProductPerGram ? true : false,
				dueDate: !!product.dueDate,
				packType: product.packType,
				packQuantity: parseInt(String(product.packQuantity)) || 0,
				priceType: product.priceType || determinePriceType(product)
			};

			const existingIndex = updatedProductParams.findIndex(param => String(param.productEan) === String(product.ean));

			if (existingIndex !== -1) {
				updatedProductParams[existingIndex] = newProductParam;
			} else updatedProductParams.push(newProductParam);
		});

		setProductParams(updatedProductParams);

		return updatedProductParams;
	}

	const getBatchList = async () => {
		const api = new Api("batch", "gbc");
		const request = api.request(member, { companyID: member.companyID });
		const { success, data } = await api.post(request);

		if (!success) return console.log("Não encontramos nenhum lote!");

		const dropdownData = data.map((batch: {
			ID: number,
			Name: string,
			CampaignID: string
		}) => ({ label: batch.Name, data: batch.ID, CampaignID: batch.CampaignID }));

		setListBatch(dropdownData);
	}

	useEffect(() => {
		if (hasRole || batchScenario === "store_batch_control") {
			const productsParamsOfUserMarket: IProductParams[] = [...productParams];
			products.forEach(product => {
				const type = determinePriceTypeAllowed(product).reverse().find(item => uniquePriceTypes.includes(item));
				const newProductParam: IProductParams = {
					productEan: String(product.ean),
					gram: false,
					dueDate: false,
					packType: 'UN',
					packQuantity: 0,
					priceType: type || 'none'
				};

				const existingIndex = productParams.findIndex(param => String(param.productEan) === String(product.ean));

				if (existingIndex !== -1) {
					productsParamsOfUserMarket[existingIndex] = newProductParam;
				} else {
					productsParamsOfUserMarket.push(newProductParam);
				}
			});
			setProductParams(productsParamsOfUserMarket);
		}
	}, [products, uniquePriceTypes]);

	//set default
	useEffect(() => { setTemplateOrientation(paperOrientation); }, [paperOrientation]);
	useEffect(() => { setProductsQuantity([]); resetQuantityInputs() }, [isReuseQtd]);

	return (
		<BatchProducts.Provider value={{
			products,
			setProducts,

			cacheProductsModal,
			setCacheProductsModal,

			productsQuantity,
			setProductsQuantity,

			selectedProducts,
			setSelectedProducts,

			productParams,
			setProductParams,

			listBatch,
			setListBatch,

			selectedMarkets,
			setSelectedMarkets,

			listAvailableDates,
			setListAvailableDates,

			batchSelected,
			setBatchSelected,

			uniquePriceTypes,
			setUniquePriceTypes,

			selectedDate,
			setSelectedDate,

			paperOrientation,

			templateOrientation,
			setTemplateOrientation,

			determinePriceType,
			determinePriceTypeAllowed,

			resetQuantityInputs,
			handleCheckProductQuantity,
			getBatchList,
			transferQuantities,

			getBatchProducts,

			productsModalManager,
			setProductsModalManager,

			isLoading,
			setIsLoading,

			isReuseQtd,
			setReuseQtd,

			productSearch,
			setProductSearch,

			adminMode,
			setAdminMode,

			adminMarket,
			setAdminMarket,

			hasRole,
			marketsCompany,

			batchScenario,

			today,
			tomorrow
		}}>
			{children}
		</BatchProducts.Provider>
	);
};

export const useBatch = (): IBatch => {
	const context = useContext(BatchProducts);

	if (!context) {
		throw new Error(
			"useBatch must be used within an BatchProductProvider"
		);
	};

	return context;
};
