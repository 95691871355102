import { IPaperQuantity } from "@/components/modals/products/search/IProductSelectionModal";
import { Orientation } from "@/types/canvas";

export type PaperSize = "_2A3" | "A3" | "_2A4" | "A4" | "A5" | "A6" | "A7";
export type PaperOtherSize = "P21" | "P30";
export type PaperOrientation = "Vertical" | "Horizontal" | "P21"

export const paperSizes: PaperSize[] = ["_2A3", "A3", "_2A4", "A4", "A5", "A6", "A7"];
export const paperOtherSize: PaperOtherSize[] = ["P21", "P30"];
export const paperOrientations: PaperOrientation[] = ["Vertical", "Horizontal", "P21"];

export type IPaperSizeOptions = {
	_2A3: boolean,
	A3: boolean,
	_2A4: boolean,
	A4: boolean,
	A5: boolean,
	A6: boolean,
	A7: boolean,
}

export type IPaperOtherSizeOptions = {
	P21: boolean,
	P30: boolean
}

export type IPaperOrientationOptions = {
	Vertical: boolean,
	Horizontal: boolean,
	P21: boolean,
	P30: boolean
}

export type IPaperFormatOptions = {
	vertical: IPaperSizeOptions,
	horizontal: IPaperSizeOptions
}

export type QueueData = {
	id: string;
	uri: string;
	createdAt: string;
	createdBy: string;
	quantity: number;
	orientation: Orientation;
}

export type IRestrictPrinting = {
	formats: IPaperFormatOptions,
	otherFormats: IPaperOtherSizeOptions,
	orientations: IPaperOrientationOptions,
	InUsePrintProductLimit: boolean,
	PrintProductLimit: number,
	FixedModalPrint: boolean,
	UseBatch: boolean,
	AdminCanPrint: boolean,
	BatchScenario: string,
}

export type IAutomation = {
	useCompanyAutomation: boolean,
	companyAutomation_campaign: string,
	companyAutomation_paperSize: PaperSize,
	companyAutomation_paperQuantity: number
	companyAutomation_paperOrientation: PaperOrientation,
	companyAutomation_singlePage: boolean
}

export const initialPaperSize: IPaperSizeOptions = {
	_2A3: false,
	A3: false,
	_2A4: false,
	A4: false,
	A5: false,
	A6: false,
	A7: false,
}

export const initialOtherSizesValue: IPaperOtherSizeOptions = {
	P21: false,
	P30: false
};

export const initialOrientationValue: IPaperOrientationOptions = {
	Vertical: false,
	Horizontal: false,
	P21: false,
	P30: false
}

export const initialPaperQuantity: IPaperQuantity = {
	_2A3: 0,
	A3: 0,
	_2A4: 0,
	A4: 0,
	A5: 0,
	A6: 0,
	A7: 0
}

export const initialPaperFormat: IPaperFormatOptions = {
	vertical: initialPaperSize,
	horizontal: initialPaperSize
}

export const initialRestrictPrinting = {
	formats: initialPaperFormat,
	othersFormats: initialOtherSizesValue,
	orientations: initialOrientationValue,
	InUsePrintProductLimit: false,
	PrintProductLimit: 0,
	FixedModalPrint: false,
	UseBatch: false,
	AdminCanPrint: false,
	BatchScenario: '',
};

export type ImageQueue = Array<QueueData>;
