import { FC } from "react"

interface ICalendarDashboard {
    isOpen?: (value: boolean) => void,
    selectDate: string;
    onDateChange?: (date: string) => void,

}
const CalendarDashboard: FC<ICalendarDashboard> = ({ isOpen, selectDate, onDateChange }) => {

    const halterDate = (event: any) => {
        if (onDateChange) {
            onDateChange(event.target.value);
        }
    }
    return (
        <>
            <input type="date" className="border border-zinc-200 text-zinc-800 text-sm rounded-lg w-full ps-4 px-2 py-1"
                placeholder="Selecione uma data"
                value={selectDate}
                onChange={halterDate}
            />
        </>

    )

}
export default CalendarDashboard;