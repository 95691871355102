import React, { useEffect, useState } from 'react';
import Spinner from '../svg/Spinner';
import { useDashboard } from '@/contexts/dashboard/DashboardContext';
import { DropdownOptions } from '../buttons/DropdownOptions';
import { useCompany } from '@/contexts/company/CompanyContext';
import { MemberContext, useMember } from '@/contexts/member/MemberContext';
import CalendarDashboard from './CalendarDashboard';

const meses: Item[] = [
    { label: "Janeiro", value: 1 },
    { label: "Fevereiro", value: 2 },
    { label: "Março", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Maio", value: 5 },
    { label: "Junho", value: 6 },
    { label: "Julho", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Setembro", value: 9 },
    { label: "Outubro", value: 10 },
    { label: "Novembro", value: 11 },
    { label: "Dezembro", value: 12 }
]

interface Item {
    label: string;
    value: number;
}
const Navbar: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { member } = useMember();
    const { company, allCompanies } = useCompany();
    const { marketsViewData, monthlyViewData, refreshDashboard, refreshMonthlyView, refreshTemplateHanking, refreshMostUsedFormats } = useDashboard();
    const currentYear = new Date().getFullYear().toString();
    const currentMonths = new Date().getMonth();
    const year = new Date().getFullYear();
    const currentMonthDayFinal = new Date(year, currentMonths + 1, 0).getDate();
    const currentDateInit = currentYear + "-" + (currentMonths + 1) + "-" + "01";
    const currentDateFin = currentYear + "-" + (currentMonths + 1) + "-" + currentMonthDayFinal;
    const currentMonthsLabel = meses[currentMonths]?.label;
    const stringInit = member.role === 'admin' ? "Todas as Empresas" : "Todas as Lojas";
    const stringConvertInit = 0;
    const [dataIn, setDataIn] = useState(currentDateInit);
    const [dataFin, setDataFin] = useState(currentDateFin);
    const [impMonth, setimpMonth] = useState(0);
    const [impYear, setimpYear] = useState(0);
    const [selctedCampaign, setLojas] = useState<Item>({ label: stringInit, value: stringConvertInit });
    const [selcteMonths, setMonths] = useState<Item>({ label: currentMonthsLabel, value: currentMonths + 1 });
    const [selctYears, setYears] = useState<Item>({ label: currentYear, value: parseInt(currentYear) });
    const [onFinish, setOnfinich] = useState();

    const isAdmin = (member.role === 'admin' || member.role === 'manager');

    const onClick = async () => {
        setIsLoading(true);
        refreshDashboard(() => setIsLoading(false));
        Promise.all([
            refreshMonthlyView(onFinish, selctedCampaign.value, dataIn, dataFin),
            refreshTemplateHanking(selctedCampaign.value, dataIn, dataFin),
            refreshMostUsedFormats(selctedCampaign.value, selcteMonths.value, selctYears.value),
        ])
            .then(([filtersResult, monthlyViewResult, templateHankingResult /*mostUsedFormatsResult*/]) => {
                // Todos os resultados das promessas foram resolvidos
                // console.log("Resultados:", filtersResult, monthlyViewResult, templateHankingResult, mostUsedFormatsResult, favoriteTemplateResult);
                // Aqui você pode manipular os resultados de cada função conforme necessário
            })
            .catch(error => {
                // Tratar qualquer erro ocorrido em qualquer uma das promessas
                console.error("Erro ao executar as funções:", error);
            });

    }
    const changeDate = (newDate: any) => {
        setDataIn(newDate); // Atualiza a data
    };
    const changeDateFin = (newDate: any) => {
        setDataFin(newDate); // Atualiza a data
    };
    const resetFilter = () => {
        setLojas({ label: stringInit, value: stringConvertInit });
        setMonths({ label: currentMonthsLabel, value: currentMonths + 1 });
        setYears({ label: currentYear, value: parseInt(currentYear) });
        setDataIn(currentDateInit);
        setDataFin(currentDateFin);

    };
    useEffect(() => {
        (async () => {
            await onClick();
            setimpMonth(totalImpressions[0]?.label || 0);
            setimpYear(monthImpressions);
        })()

    }, [selctedCampaign, selcteMonths, selctYears])

    const markets = (member.role === 'admin' && Array.isArray(allCompanies)) ? [{ label: "Todas as Empresas", value: 0 }, ...allCompanies.map((el) => {
        return { label: el.name, value: el.id };
    })] : company
        ? [{ label: "Todas as Lojas", value: 0 }, ...company.markets.map((el) => {
            return { label: el.name, value: el.id };
        })]
        : [];

    const totalImpressions = monthlyViewData.map((el) => {
        return { label: el.impressions }
    })

    const monthImpressions = marketsViewData.reduce((acc, el) => acc + el.quantidade, 0);

    useEffect(() => {
        setimpMonth(totalImpressions[0]?.label || 0);
        setimpYear(monthImpressions);
    })

    return (
        <nav className="bg-white border-b p-2 px-4 pt-4 flex flex-wrap justify-between items-center space-y-4 md:space-y-0">
            <h1 className="text-zinc-600 text-lg font-bold w-full md:w-auto">Dashboard</h1>
            {isAdmin ? (
                <>
                    <div className="flex flex-wrap md:flex-nowrap items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto">
                        <div className="w-full md:w-56 flex flex-col justify-center">
                            <span className="text-zinc-600 font-medium text-sm">Lojas:</span>
                            <DropdownOptions
                                title={selctedCampaign.label}
                                setTitle={(el) => setLojas({ label: el.label, value: el.value })}
                                items={markets}
                            // returnObject={true}
                            />
                        </div>
                        <div className="w-full md:w-56 flex flex-col justify-center">
                            <span className="text-zinc-600 font-medium text-sm">Data Início:</span>
                            <CalendarDashboard selectDate={dataIn} onDateChange={changeDate} />
                        </div>
                        <div className="w-full md:w-56 flex flex-col justify-center">
                            <span className="text-zinc-600 font-medium text-sm">Data Fim:</span>
                            <CalendarDashboard selectDate={dataFin} onDateChange={changeDateFin} />
                        </div>
                    </div>
                    <div className='space-x-4 flex justify-center items-center'>
                        <div className=" flex flex-col items-center md:items-start justify-center text-center md:text-left mt-2">
                            <span className="text-zinc-600 font-semibold text-xs">IMPRESSÕES POR MÊS</span>
                            <span className="text-zinc-600 font-bold text-sm">Total: {impYear}</span>
                        </div>
                        <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left mt-2">
                            <span className="text-zinc-600 font-semibold text-xs">IMPRESSÕES POR ANO</span>
                            <span className="text-zinc-600 font-bold text-sm">Total: {impMonth}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-center space-x-4 ">
                        {isLoading && <div className="text-gray-600"><Spinner /></div>}
                        <button onClick={onClick} className="bg-green-500 shadow-md shadow-green-500/50 text-white py-2 px-4 rounded-lg mt-2 md:mt-2">
                            Atualizar
                        </button>
                        <button onClick={resetFilter} className="bg-red-500 shadow-md shadow-red-500/50 text-white py-2 px-4 rounded-lg mt-2 md:mt-2">
                            Limpar
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
                        <span className="text-zinc-600 font-bold text-xs">IMPRESSÕES POR MÊS</span>
                        <span className="text-zinc-600 font-bold text-sm">Total: {impYear}</span>
                    </div>
                    <div className="flex flex-col items-center md:items-start justify-center text-center md:text-left">
                        <span className="text-zinc-600 font-bold text-xs">IMPRESSÕES POR ANO</span>
                        <span className="text-zinc-600 font-bold text-sm">Total: {impMonth}</span>
                    </div>
                    <button onClick={onClick} className="bg-green-500 shadow-md shadow-green-500/50 text-white py-2 px-4 rounded-lg mt-4 md:mt-0">
                        Atualizar
                    </button>
                </>
            )}
        </nav>
    );
};
export default Navbar;
