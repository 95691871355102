import { useModals } from "@/contexts/modals/ModalsContext";
import React from "react";

interface DynamicTextModalInterface { }

interface LabelInputProps {
	labelText: string;
	inputType: string;
	value: string | number;
	updateFunction: (value: string | number) => void;
	helperText?: string;
}

const DynamicTextModal: React.FC<DynamicTextModalInterface> = ({ }) => {
	const { showDynamicTextModal, setShowDynamicTextModal } = useModals();

	const closeModal = () => {
		setShowDynamicTextModal(false);
	}

	const handleOverlayClick = (e: React.MouseEvent): void => {
		e.stopPropagation();
		closeModal();
	};

	const formatMoney = (value: number): string => {
		// Adiciona zeros à esquerda se necessário,
		// e insere a vírgula na posição correta.
		const str = value.toString().padStart(3, '0');
		const finalStr = str.slice(0, -2) + ',' + str.slice(-2);
		return finalStr;
	};

	return (
		<React.Fragment>
			<div
				className={`fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-500 ease-in-out z-70 ${showDynamicTextModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
				onClick={handleOverlayClick}>
			</div>

			<div
				className={`fixed top-0 left-0 h-screen z-70 min-w-fit transition-all duration-300 ease-in-out ${showDynamicTextModal ? 'transform-none opacity-100 pointer-events-auto' : 'transform -translate-x-full opacity-0 pointer-events-none'}`}>

				<div className="flex flex-col justify-start w-[34rem] pt-4 h-full bg-white shadow-lg overflow-auto">
					<span className="text-xl text-center font-bold mb-4">Personalizar Texto Promocional</span>

				</div>
			</div>

		</React.Fragment>
	);
}

export default DynamicTextModal;
