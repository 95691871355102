import React from 'react';
import { Menu } from '@headlessui/react';
import { ArrowDown } from '@/components/svg/Arrows';
import TextHelper from '@/helpers/TextHelper';

export type Item = {
    label: string,
    data?: any
}

interface DropdownButtonInterface {
    title: string | null,
    setTitle: (item: any) => void,
    items: Item[] | string[],
    disable?: boolean,
    defaultTitle?: string
}

const DropdownOptions: React.FC<DropdownButtonInterface> = ({ title, setTitle, items, disable, defaultTitle }) => {
    const onClickOption = (e: React.MouseEvent, item: any) => {
        e.stopPropagation();
        setTitle(item);
    }

    return (
        <Menu as="div" className="w-full relative text-left">
            <Menu.Button disabled={disable} className={`${disable && "bg-zinc-100 cursor-not-allowed"} w-full flex items-center justify-between border border-zinc-200 px-4 py-1 text-zinc-900 rounded-lg  hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span className='flex w-full'>
                    {title ?
                        (typeof title !== "string" ? (title as Item).label : title as string)
                        : defaultTitle ? defaultTitle : "Selecione um Cartaz"}
                </span>
                <ArrowDown className='ml-2 m-auto stroke-zinc-500 fill-zinc-500' />
            </Menu.Button>

            <Menu.Items className="origin-top-left absolute right-0 mt-1 w-full rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-transform duration-200 transform scale-95 ease-in-out z-10">
                <div className="max-h-[30vh] rounded-lg overflow-auto divide-y" onClick={e => e.stopPropagation()}>
                    {
                        items.length > 0 ? (
                            items.map((item, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <div
                                            onClick={(e) => onClickOption(e, item)}
                                            className={`${active || title === item ? 'bg-blue-500 text-white' : 'text-gray-700'
                                                } flex justify-between w-full px-4 py-2 cursor-pointer`}
                                        >
                                            <span>
                                                {
                                                    TextHelper.charLimit(
                                                        typeof item !== 'string' ? item.label : item,
                                                        40
                                                    )
                                                }
                                            </span>
                                        </div>
                                    )}
                                </Menu.Item>
                            ))
                        ) : (
                            <div
                                className={`text-gray-700 flex justify-between
                                    w-full px-4 py-2 cursor-pointer`}>
                                <span className='text-zinc-300'>Vazio...</span>
                            </div>
                        )
                    }
                </div>
            </Menu.Items>
        </Menu>
    )
}

const DropdownOptionsCompact: React.FC<DropdownButtonInterface> = ({ title, setTitle, items, disable, defaultTitle }) => {
    const onClickOption = (e: React.MouseEvent, item: any) => {
        e.stopPropagation();
        setTitle(item);
    }

    return (
        <Menu as="div" className="w-full relative text-left">
            <Menu.Button disabled={disable} className={`${disable && "bg-zinc-100 cursor-not-allowed"} w-full flex items-center justify-between border border-zinc-200 px-3 py-1 text-zinc-900 rounded-lg  hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span className='flex w-full'>
                    {title ?
                        (typeof title !== "string" ? (title as Item).label : title)
                        : defaultTitle ? defaultTitle : "Selecione uma Data"}
                </span>
                <ArrowDown className='ml-2 m-auto stroke-zinc-500 fill-zinc-500' />
            </Menu.Button>

            <Menu.Items className="origin-top-left absolute right-0 mt-1 w-full rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-transform duration-200 transform scale-95 ease-in-out z-10">
                <div className="max-h-[30vh] rounded-lg overflow-auto divide-y" onClick={e => e.stopPropagation()}>
                    {
                        items.length > 0
                            ?
                            items.map((item, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <div
                                            onClick={(e) => onClickOption(e, item)}
                                            className={`${active ? 'bg-blue-500 text-white' : 'text-gray-700'
                                                } flex justify-between w-full px-4 py-2 cursor-pointer`}
                                        >
                                            <span>
                                                {
                                                    TextHelper.charLimit(
                                                        typeof item !== 'string' ? item.label : item, 40
                                                    )
                                                }
                                            </span>
                                        </div>
                                    )}
                                </Menu.Item>
                            ))
                            :
                            <div
                                className={`text-gray-700 flex justify-between
                                    w-full px-4 py-2 cursor-pointer`}>
                                <span className='text-zinc-300'>Vazio...</span>
                            </div>}
                </div>
            </Menu.Items>
        </Menu>
    )
}

export { DropdownOptions, DropdownOptionsCompact };