import React from 'react';
import { ParentInterface } from "@/types";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query'
import { CanvasProvider } from "@/contexts/editor/CanvasContext";
import { ModelsProvider } from '@/contexts/models/ModelsContext';
import { ProductsProvider } from '@/contexts/products/ProductsContext';
import { CanvasProductsProvider } from '@/contexts/editor/CanvasProductsContext';
import { ModalProvider } from '@/contexts/modals/ModalsContext';
import { OnboardingProvider } from '@/contexts/onboarding/OnboardingContext';
import { CompanyProvider } from '@/contexts/company/CompanyContext';
import { NotificationProvider } from '@/contexts/notification/NotificationContext';
import { WSProvider } from '@/contexts/ws/WSContext';
import { OpenAIProvider } from '@/contexts/openai/OpenAIContext';
import { QueueApiActions, QueueProvider } from '@/contexts/queue/QueueContext';
import { DashboardProvider } from '@/contexts/dashboard/DashboardContext';
import { SearchProvider } from '@/contexts/products/SearchContext';
import { PricingProvider } from '@/contexts/pricing/PricingContext';
import { GlobalProvider } from '@/contexts/app/GlobalContext';
import { DynamicPromoProvider } from '@/contexts/editor/DynamicPromoContext';
import MemberProvider, { useMember } from "@/contexts/member/MemberContext";
import Layout from '@/components/Layout';
import store from "@/store";
import { FontProvider } from './fonts/FontContext';
import { TaskProvider } from './tasks/TaskContext';

const ApplicationProvider: React.FC<ParentInterface> = ({ children }) => {
    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <WSProvider>
                <QueryClientProvider client={queryClient}>
                    <GlobalProvider>
                        <MemberRealm>
                            <FontProvider>
                                <NotificationProvider>
                                    <Provider store={store}>
                                        <OnboardingProvider>
                                            <CanvasRealm>
                                                <OpenAIProvider>
                                                    <TaskProvider>
                                                        <DashboardProvider>
                                                            <Layout>
                                                                {children}
                                                            </Layout>
                                                        </DashboardProvider>
                                                    </TaskProvider>
                                                </OpenAIProvider>
                                            </CanvasRealm>
                                        </OnboardingProvider>
                                    </Provider>
                                </NotificationProvider>
                            </FontProvider>
                        </MemberRealm>
                    </GlobalProvider>
                </QueryClientProvider>
            </WSProvider>
        </React.Fragment>
    )
}

export default ApplicationProvider;

const MemberRealm: React.FC<ParentInterface> = ({ children }) => {
    return (
        <React.Fragment>
            <MemberProvider>
                <CompanyProvider>
                    {children}
                </CompanyProvider>
            </MemberProvider>
        </React.Fragment>
    )
}

const CanvasRealm: React.FC<ParentInterface> = ({ children }) => {
    const { member } = useMember();
    const isManager = member.can('manage_island');

    const queueApi: QueueApiActions = {
        get: isManager ? 'gm' : 'g',
        add: 'c',
        remove: 'r'
    }

    return (
        <React.Fragment>
            <ModalProvider>
                <DynamicPromoProvider>
                    <CanvasProvider>
                        <SearchProvider>
                            <ProductsProvider>
                                <CanvasProductsProvider>
                                    <QueueProvider queueApi={queueApi} markets={isManager}>
                                        <ModelsProvider>
                                            <PricingProvider>
                                                {children}
                                            </PricingProvider>
                                        </ModelsProvider>
                                    </QueueProvider>
                                    {/* Fonts provider */}
                                    {/* <FontsContainer /> */}
                                </CanvasProductsProvider>
                            </ProductsProvider>
                        </SearchProvider>
                    </CanvasProvider>
                </DynamicPromoProvider>
            </ModalProvider>
        </React.Fragment>
    )
}
