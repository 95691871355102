import { useDynamicPromo } from "./DynamicPromoContext";

interface IManagePackOffer {
    calculatePack: (price: string) => string[];
}

type PackOfferFactory = () => IManagePackOffer;

// Testando nova estrutura para separacao de responsabilidades.
// Funcao para agrupar funcionalidades referente ao packOffer. 
const manageOfferPack: PackOfferFactory = () => {
    const { oferta_pack } = useDynamicPromo();

    const calculatePack = (price: string) => {
        const packPrice = parseFloat(price as string) * oferta_pack.quantity;
        return packPrice.toFixed(2).split(".");
    }

    return {
        calculatePack
    };
}

export default manageOfferPack;