import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

interface PriceInputInterface {
	onChange: (value: string) => void;
	defaultValue?: string;
	placeholder?: string;
	required?: boolean;
	error?: boolean;
	disabled?: boolean;
}

const PriceInput: React.FC<PriceInputInterface> = ({
	onChange,
	defaultValue,
	placeholder,
	required,
	error,
	disabled,
}) => {
	const [value, setValue] = useState(defaultValue);
	const [hasError, setHasError] = useState(error);

	const containsLetters = (str: string) => /[a-zA-Z]/.test(str)

	useEffect(() => {
		if (required) {
			setHasError(!value || !Boolean(value.length) || error);
		}
		else setHasError(error);
	}, [value, error]);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;

		if (!newValue) return setValue("0,00");
		if (containsLetters(newValue)) return;
		if (newValue.length > 8) return;

		const sanitizedValue = newValue.replace(/[^0-9]/g, "");
		const valueAsNumber = parseInt(sanitizedValue, 10);
		const formattedValue = (valueAsNumber / 100).toLocaleString("pt-BR", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		setValue(formattedValue);
		onChange(formattedValue);
	};

	const clearInputValue = () => {
		setValue("0,00");
		onChange("");
	};

	const inputClasses = `border-solid border border-zinc-200 duration-300 ${hasError ? 'border-red-200' : 'hover:border-orange-400'}`;
	const iconClasses = `mx-2  dark:text-black ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`;

	return (
		<div className="w-full">
			<div className="flex justify-center w-full">
				<div
					className={`${inputClasses} flex-1 w-full rounded-lg flex items-center bg-zinc-100`}
				>
					<input
						type={'text'}
						inputMode="decimal"
						placeholder={placeholder}
						className={`h-full w-full rounded-l-lg outline-none px-4 py-1.5 ${disabled && "cursor-not-allowed"
							}`}
						value={value}
						onChange={handleChange}
						disabled={disabled}
					/>
					{
						value ?
							(
								<span className={iconClasses} onClick={!disabled ? clearInputValue : undefined}>
									<CIcon className="text-zinc-500" icon={freeSet.cilX} width={20} size="xxl" />
								</span>
							)
							: (
								<span className={iconClasses}>
									<CIcon className="text-zinc-500" icon={freeSet.cilPen} width={20} size="xxl" />
								</span>
							)
					}
				</div>
			</div>
		</div>
	);
};

PriceInput.defaultProps = {
	defaultValue: "",
	placeholder: "Digite o valor...",
	required: false,
	error: false,
	disabled: false
};

export default PriceInput;
