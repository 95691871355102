import React from 'react';
import Row from "@/components/modals/admin/components/Row";
import { DropdownList } from 'react-widgets/cjs';
import { RenderItemProp } from 'react-widgets/cjs/List';

interface DropdownRowInterface {
	label: string,
	value: any,
	data: any,
	onChange: (value: any) => void,
	disabled?: boolean,
	dataKey?: string,
	textField?: string,
	placeholder?: string,
	renderListItem?: RenderItemProp<any>,
	rowClassName?: string,
	labelClassName?: string,
	dropdownClassName?: string
}

const DropdownRow: React.FC<DropdownRowInterface> = ({
	label,
	value,
	data,
	onChange,
	disabled,
	dataKey,
	textField,
	placeholder,
	renderListItem,
	rowClassName,
	labelClassName,
	dropdownClassName
}) => {
	return (
		<Row className={rowClassName} >
			<span className={labelClassName} >
				{label}
			</span>
			<div className={dropdownClassName} >
				<DropdownList
					filter={false}
					onChange={(option: any) => onChange(option)}
					className="cursor-default"
					{...{ data, value, dataKey, textField, disabled, renderListItem, placeholder }}
				/>
			</div>
		</Row>
	)
}

DropdownRow.defaultProps = {
	disabled: false,
	placeholder: 'Selecione',
	rowClassName: '',
	labelClassName: 'mr-4 w-2/5',
	dropdownClassName: 'w-3/5'
}

export default DropdownRow;
