import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import { DateTime } from "luxon";
import { PackType } from "@/types/products";

interface DynamicPromoProviderInterface {
    children: ReactChildren
}

interface DynamicPromoContextData {
    cpf_limit: number;

    date_start: string | null;

    date_limit: string | null;

    expiration_date: string | null;

    price_a_cada: string;

    ap_x_UN: ApXUNType;

    leve_Por: LevePORType;

    oferta_pack: OfertaPackType;

    xOff_xUN: XOffType;

    compre_e_ganhe: CompreEGanheType;

    leveX_pagueY: LeveXPagueYType;

    maisX_Leve: MaisXXLeve;

    compreX_MaisX_Leve: CompreXMaisXLeve;

    installmentPrice: InstallmentType;

    text_club: string;

    updateCPFLimit: (value: number) => void;
    updateStartDate: (value: string | null) => void;
    updateDateLimit: (value: string | null) => void;
    updateExpirationDate: (value: string | null) => void;
    updatePriceACada: (value: string) => void;
    updateApXUN: (value: ApXUNType) => void;
    updateLEVEPOR: (value: LevePORType) => void;
    updateXOff: (value: XOffType) => void;
    updateCompreEGanhe: (value: CompreEGanheType) => void;
    updateLeveXPagueY: (value: LeveXPagueYType) => void;
    updateMaisX: (value: MaisXXLeve) => void;
    updateCompreXMaisXLeve: (value: CompreXMaisXLeve) => void;
    updateInstallmentPrice: (value: InstallmentType) => void;
    updateTextClub: (value: string) => void;
    updateOfertaPack: (value: OfertaPackType) => void;

    pack: PackType,
    updatePack: (value: PackType) => void;
}

export type SetterName = 'cpf_limit_enabled' | 'date_start_enabled' | 'date_limit_enabled' | 'expiration_date_enabled' | 'price_a_cada_enabled'
    | 'ap_x_UN_enabled' | 'xOff_xUN_enabled' | 'compre_e_ganhe_enabled' | 'leveX_pagueY_enabled' | 'maisX_Leve_enabled' | 'compreX_MaisX_Leve_enabled'
    | 'installments_enabled' | "text_club_enabled" | 'leve_Por_enabled' | "oferta_pack_enabled";
export type ApXUNType = { quantity: number, price: string };
export type XOffType = { xOff: number, xUN: number, price: string };
export type LevePORType = { quantity: number, price: string };
export type CompreEGanheType = { compre: number, ganhe: string };
export type LeveXPagueYType = { leve: number, pague: number };
export type MaisXXLeve = { maisX: string, leve: string };
export type CompreXMaisXLeve = { compreX: number, maisX: string, leve: string };
export type InstallmentType = { quantity: number, price: string };
export type OfertaPackType = { quantity: number, price: string };

const DynamicPromoContext = createContext<DynamicPromoContextData>(
    {} as DynamicPromoContextData
);

export const DynamicPromoProvider: React.FC<DynamicPromoProviderInterface> = ({ children }) => {
    const [cpf_limit, setCPF_Limit] = useState<number>(0);

    const [date_start, setDate_start] = useState<string | null>(DateTime.now().toISODate());

    const [date_limit, setDate_Limit] = useState<string | null>(DateTime.now().toISODate());

    const [expiration_date, setExpiration_Date] = useState<string | null>(DateTime.now().toISODate());

    const [price_a_cada, setPrice_A_Cada] = useState<string>("0,00");

    const [ap_x_UN, setAp_x_UN] = useState<ApXUNType>({ quantity: 2, price: "0,00" });

    const [leve_Por, setlevePor] = useState<LevePORType>({ quantity: 2, price: "0,00" });

    const [xOff_xUN, setXOff_xUN] = useState<XOffType>({ xOff: 0, xUN: 1, price: "0,00" });

    const [compre_e_ganhe, setCompre_e_Ganhe] = useState<CompreEGanheType>({ compre: 1, ganhe: "DESCRIÇÃO DO ITEM" });

    const [leveX_pagueY, setLeveX_PagueY] = useState<LeveXPagueYType>({ leve: 2, pague: 1 });

    const [maisX_Leve, setMaisX_Leve] = useState<MaisXXLeve>({ maisX: "0,00", leve: "DESCRIÇÃO DO ITEM" });

    const [compreX_MaisX_Leve, setCompreX_MaisX_Leve] = useState<CompreXMaisXLeve>({ compreX: 2, maisX: "0,00", leve: "DESCRIÇÃO DO ITEM" });

    const [installmentPrice, setInstallmentPrice] = useState<InstallmentType>({ quantity: 2, price: "0,00" });

    const [text_club, setText_clube] = useState<string>("");

    const [pack, setPack] = useState<PackType>("UN");

    const [oferta_pack, setOferta_Pack] = useState<LevePORType>({ quantity: 2, price: "0,00" });

    useEffect(() => {
        if (text_club) localStorage.setItem("text_club", text_club);
    }, [text_club]);

    useEffect(() => {
        const getTextClubFromCache = localStorage.getItem("text_club");
        if (getTextClubFromCache) setText_clube(getTextClubFromCache);
    }, []);

    const updatePack = (value: PackType) => setPack(value);

    const updateCPFLimit = (limit: number) => {
        setCPF_Limit(limit);
    }

    const updateStartDate = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setDate_start(formattedDate);
    }

    const updateDateLimit = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setDate_Limit(formattedDate);
    }

    const updateExpirationDate = (date: string | null) => {
        if (!date) return;
        const dt = DateTime.fromISO(date);
        const formattedDate = dt.isValid ? dt.toISODate() : null;

        setExpiration_Date(formattedDate);
    }

    const updatePriceACada = (value: string) => {
        setPrice_A_Cada(value);
    }

    const updateApXUN = (value: ApXUNType) => {
        setAp_x_UN(value);
    }

    const updateLEVEPOR = (value: LevePORType) => {
        setlevePor(value);
    }
    const updateXOff = (value: XOffType) => {
        setXOff_xUN(value);
    }

    const updateCompreEGanhe = (value: CompreEGanheType) => {
        setCompre_e_Ganhe(value);
    }

    const updateLeveXPagueY = (value: LeveXPagueYType) => {
        setLeveX_PagueY(value);
    }

    const updateMaisX = (value: MaisXXLeve) => {
        setMaisX_Leve(value);
    }

    const updateCompreXMaisXLeve = (value: CompreXMaisXLeve) => {
        setCompreX_MaisX_Leve(value);
    }

    const updateInstallmentPrice = (value: InstallmentType) => {
        setInstallmentPrice(value);
    }

    const updateTextClub = (value: string) => {
        setText_clube(value);
    }

    const updateOfertaPack = (value: OfertaPackType) => {
        setOferta_Pack(value);
    }

    return (
        <DynamicPromoContext.Provider
            value={{
                cpf_limit,
                date_start,

                date_limit,
                expiration_date,
                price_a_cada,
                ap_x_UN,
                leve_Por,
                xOff_xUN,
                compre_e_ganhe,
                leveX_pagueY,
                maisX_Leve,
                compreX_MaisX_Leve,
                installmentPrice,
                text_club,
                oferta_pack,
                updateCPFLimit,
                updateStartDate,
                updateDateLimit,
                updateExpirationDate,
                updatePriceACada,
                updateApXUN,
                updateLEVEPOR,
                updateXOff,
                updateCompreEGanhe,
                updateLeveXPagueY,
                updateMaisX,
                updateCompreXMaisXLeve,
                updateInstallmentPrice,
                updateTextClub,
                updateOfertaPack,

                pack,
                updatePack
            }}
        >
            {children}
        </DynamicPromoContext.Provider>
    );
};

export const useDynamicPromo = (): DynamicPromoContextData => {
    const context = useContext(DynamicPromoContext);

    if (!context) {
        throw new Error(
            "useDynamicPromo must be used within a DynamicPromoProvider"
        );
    }

    return context;
};

export function getTextTypeLabel(type: SetterName): string {
    switch (type) {
        case 'cpf_limit_enabled': return 'Limite de CPF';
        case 'date_limit_enabled': return 'Data Limite';
        case 'expiration_date_enabled': return 'Data de Expiração';
        case 'price_a_cada_enabled': return 'Preço a Cada';
        case 'ap_x_UN_enabled': return 'A Partir de X UN';
        case 'xOff_xUN_enabled': return 'X% Off';
        case 'compre_e_ganhe_enabled': return 'Compre e Ganhe';
        case 'leveX_pagueY_enabled': return 'Leve X Pague Y';
        case 'maisX_Leve_enabled': return 'Mais X Leve';
        case 'compreX_MaisX_Leve_enabled': return 'Compre X Mais X Leve';
        case 'leve_Por_enabled': return 'Leve x Por:';
        case 'oferta_pack_enabled': return 'Oferta Pack';
        default: return '';
    }
}
