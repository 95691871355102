import React from 'react';
import { createContext, useContext, useState, useEffect } from "react";
import { Orientation } from "@/types/canvas";
import { ReactChildren } from "@/types";
import { APIImageQueue, QueueData } from "@/types/queue";
import { useMember } from "@/contexts/member/MemberContext";
import Notify from "@/helpers/Notify";
import Api from "@/api/Api";
import { DateTime } from "luxon";
import { EntityID } from "@/types/silverstripe";
import { useCompany } from "@/contexts/company/CompanyContext";
import { ProductListItem } from "@/types/products";
import { useCanvas, useCanvasDispatch } from "../editor/CanvasContext";
import { initialOrientationValue, initialPaperFormat, initialPaperSize, IPaperFormatOptions, IPaperOrientationOptions, IPaperSizeOptions, PaperSize } from '../pdf/interfaces';
import { usePathname } from 'next/navigation';

type StoreDetails = { id: EntityID, name: string };

export type QueueApiActions = {
	get: string,
	add: string,
	remove: string
}

export type ISize = {
	_2A3: number
	A3: number
	_2A4: number
	A4: number
	A5: number
	A6: number
	A7: number
}

interface QueueContextData {
	areAllValuesFalse: (obj: { [key: string]: boolean }) => boolean;
	queueCount: number;
	addToQueue: (product: ProductListItem) => void;
	addToQueuePromise: (
		product: ProductListItem,
		printQuantity?: ISize,
		disableNotify?: boolean
	) => Promise<unknown>;
	pdfModalVisible: boolean;
	setPDFModalVisibility: (value: boolean) => void;
	addImageToQueue: (
		uri: string,
		orientation: Orientation,
		name: string,
		templateId: EntityID,
		product: ProductListItem,
		printQuantity?: ISize,
		disableNotify?: boolean
	) => Promise<boolean | void>;
	getFilteredQueue: (seed?: APIImageQueue | null) => APIImageQueue;
	filterByFormat: (targetFormat: Orientation) => APIImageQueue;
	getSelectedQueue: (seed?: APIImageQueue | null) => APIImageQueue;
	getQueueByOrientation: () => Record<string, QueueData[]>;
	filteredQueue: APIImageQueue;
	queue: Record<string, APIImageQueue>;
	setQueue: (images: Record<string, APIImageQueue>) => void;
	updateQuantity: (id: string, quantity: ISize) => void;
	clearQueue: () => void;
	margin: number;
	setMargin: (value: number) => void;
	papers: Array<PaperSize>;
	setPapers: (papers: Array<PaperSize>) => void;
	format: Orientation;
	setFormat: (value: Orientation) => void;
	removeItem: (id: string) => void;
	removeIds: (ids: string[]) => void;
	fetchQueueItems: (onFinish?: Function) => Promise<void>;
	isFetchingList: boolean;
	setIsFetchingList: (value: boolean) => void;
	selectedMarket: EntityID;
	setSelectedMarket: (id: EntityID) => void;
	storeDetails: Array<StoreDetails>;
	selectedItems: string[];
	setSelectedItems: (values: string[]) => void;
	showSelection: boolean;
	setShowSelection: (value: boolean) => void;
	count: (format: Orientation) => number;
	allowedFormats: IPaperFormatOptions;
	setAllowedFormats: (value: IPaperFormatOptions) => void;
	allowedOrientations: IPaperOrientationOptions;
	setAllowedOrientations: (value: IPaperOrientationOptions) => void;
	fullPages: IPaperSizeOptions;
	setFullPages: (value: IPaperSizeOptions) => void;
	errorAddImageToQueue: boolean;
}

interface QueueProviderInterface {
	children: ReactChildren,
	queueApi: QueueApiActions,
	markets: boolean
}

const QueueContext = createContext<QueueContextData>(
	{} as QueueContextData
);

export const QueueProvider: React.FC<QueueProviderInterface> = ({ children, queueApi, markets = false }) => {
	const { member, isFetchingData } = useMember();
	const { company } = useCompany();

	const [pdfModalVisible, setPDFModalVisibility] = useState(false);
	const [queue, setQueue] = useState<Record<string, APIImageQueue>>({
		default: [],
	});
	const [filteredQueue, setFilteredQueue] = useState<APIImageQueue>([]);
	const [margin, setMargin] = useState<number>(0);
	const [papers, setPapers] = useState<Array<PaperSize>>(["A4"]);
	const [format, setFormat] = useState<Orientation>("vertical");
	const [isFetchingList, setIsFetchingList] = useState(false);
	const [queueCount, setQueueCount] = useState<number>(0);
	const [selectedMarket, setSelectedMarket] = useState<EntityID>(null);
	const [storeDetails, setStoreDetails] = useState<Array<StoreDetails>>([]);
	const [allowedFormats, setAllowedFormats] = useState<IPaperFormatOptions>(initialPaperFormat);
	const [allowedOrientations, setAllowedOrientations] =
		useState<IPaperOrientationOptions>(initialOrientationValue);
	const [fullPages, setFullPages] = useState<IPaperSizeOptions>(initialPaperSize);
	const [errorAddImageToQueue, setErrorAddImageToQueue] = useState<boolean>(false);

	// Selection controls
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [showSelection, setShowSelection] = useState(false);
	const path = usePathname() === '/fila' ? '/products' : usePathname();

	const {
		state,
		getStageImageUri,
		setIsGeneratingImage,
		isGeneratingImage
	} = useCanvas();
	const { stateDispatch } = useCanvasDispatch();

	const {
		templateName,
		templateId,
		editor
	} = state;

	useEffect(() => {
		if (!isFetchingData) {
			fetchQueueItems();
		}
	}, [isFetchingData, company]);

	useEffect(() => {
		const queue = getFilteredQueue();
		setFilteredQueue(queue);
	}, [queue, format, selectedMarket]);

	const areAllValuesFalse = (obj: { [key: string]: boolean }) => {
		return !Object.values(obj).some((value) => value === true);
	};

	const fetchQueueItems = async (onFinish: Function | undefined = undefined) => {
		const api = new Api('queue', queueApi.get);
		const request = api.request(member);

		setIsFetchingList(true);
		const response = await api.post(request);

		if (response.success && response.data.queueList) {
			if (markets) {
				const newStoreDetails: { id: EntityID, name: string }[] = !company ? []
					: company.markets.map((market) => ({ id: market.id, name: market.name, storeID: market.storeID }));

				const newQueue: Record<string, APIImageQueue> = {};
				for (const marketId in response.data.queueList) {
					const marketItems = response.data.queueList[marketId];
					if (marketItems.length > 0) {
						newQueue[marketId] = marketItems.map((item: any) => {
							const created = dateToString(item.CreatedAt);
							if (path === "/batch" && item.Path !== path) return;
							return {
								id: item.ID,
								name: item.Name,
								uri: item.Uri,
								createdAt: created,
								createdBy: item.CreatedBy || '',
								_2A3: item._2A3 || 0,
								A3: item.A3 || 0,
								_2A4: item._2A4 || 0,
								A4: item.A4 || 0,
								A5: item.A5 || 0,
								A6: item.A6 || 0,
								A7: item.A7 || 0,
								orientation: item.Orientation,
								products: item.Products,
								templateHash: item.TemplateHash,
								templateName: item.TemplateName,
								path: item.Path
							}
						}).filter(
							(item: string | any[] | null | undefined) => {
								return item !== null &&
									item !== undefined &&
									!(Array.isArray(item)
										&& item.length === 0
									);
							}
						);
						if (member.marketID && newQueue[member.marketID]) {
							newQueue['default'] = newQueue[member.marketID];
						}
					}
				}

				setQueueCount(queueCount);
				setStoreDetails(newStoreDetails);
				setQueue(newQueue);
				if (Array.from(newStoreDetails).length > 0) setSelectedMarket(newStoreDetails[0].id);

				const copyQueue = { ...newQueue };
				delete copyQueue['default']; // remove para não fazer a contagem duas vezes.

				const count = Object.values(copyQueue)
					.reduce((total, marketQueue) => total + marketQueue
						.reduce((acc) => acc + 1, 0),
						0
					);

				setQueueCount(count);
			} else {
				const queueList = response.data.queueList.map((item: any) => {
					const created = dateToString(item.CreatedAt);
					if (path === "/batch" && item.Path !== path) return;
					return {
						id: item.ID,
						name: item.Name,
						uri: item.Uri,
						createdAt: created,
						createdBy: item.CreatedBy || '',
						_2A3: item._2A3 || 0,
						A3: item.A3 || 0,
						_2A4: item._2A4 || 0,
						A4: item.A4 || 0,
						A5: item.A5 || 0,
						A6: item.A6 || 0,
						A7: item.A7 || 0,
						orientation: item.Orientation,
						products: item.Products,
						templateHash: item.TemplateHash,
						templateName: item.TemplateName
					};
				}).filter(
					(item: string | any[] | null | undefined) => {
						return item !== null &&
							item !== undefined &&
							!(Array.isArray(item)
								&& item.length === 0
							);
					}
				);

				setQueue({ default: queueList });
				setQueueCount(queueList.reduce((acc: number, item: any) => acc + 1, 0));
			}
		}
		setIsFetchingList(false);

		if (onFinish && typeof onFinish === 'function') onFinish();
	};

	const addImageToQueue = async (
		uri: string,
		orientation: Orientation,
		name: string,
		templateID: EntityID,
		product: ProductListItem,
		printQuantity?: ISize,
		disableNotify: boolean = false
	) => {
		if (!uri || !orientation) return;

		const api = new Api('queue', 'c');
		const payload = {
			Name: name,
			Orientation: orientation,
			Products: JSON.stringify([product]),
			ProductID: product.id,
			Path: path
		};

		const request = api.request(member, {
			printQuantity: printQuantity ? JSON.stringify(printQuantity) : null,
			templateHash: templateID,
			payload,
			uri
		});

		const { success, code } = await api.post(request);

		if (disableNotify) {
			if (!success && code === "print_limit") {
				return setErrorAddImageToQueue(true);
			}

			return setErrorAddImageToQueue(false);
		}

		if (!success && code === "print_limit") {
			return Notify.Error("Este produto excedeu o limite de uso diário definido pelo gerente.");
		}

		return Notify.Success("Cartazes foram gerados e adicionados à fila.");
	}

	const addToQueue = (product: ProductListItem) => {
		if (isGeneratingImage) return;

		stateDispatch({ type: "unselect" });
		setIsGeneratingImage(true);
		// Aguarda deseleção para gerar imagem.
		setTimeout(() => {
			const image = getStageImageUri(3);
			if (image && templateName && templateId) {
				addImageToQueue(image, editor.orientation, templateName, templateId, product);
			}
			else if (!templateName) Notify.Warn("Você precisa especificar o nome da campanha.");
			else if (!templateId) Notify.Error("Template não encontrado.");
			setIsGeneratingImage(false);
		}, 350);
	}

	const addToQueuePromise = async (product: ProductListItem, printQuantity?: ISize, disableNotify: boolean = false) => {
		return new Promise<void>((resolve, reject) => {
			if (isGeneratingImage) return;

			setIsGeneratingImage(true);

			if (!templateName) {
				Notify.Warn("Você precisa especificar o nome da campanha.");
				return resolve();
			}

			if (!templateId) {
				Notify.Error("Template não encontrado.");
				return resolve();
			}

			const image = getStageImageUri(3);

			if (image && templateName && templateId) {
				const result = addImageToQueue(image, editor.orientation, templateName, templateId, product, printQuantity, disableNotify);
				if (disableNotify && !result) reject(result);
				resolve();
			}

			setIsGeneratingImage(false);
			resolve();
		});
	};


	const getFilteredQueue = (seed: APIImageQueue | null = null) => {
		const market = selectedMarket ?? 'default';
		const source = seed ?? (queue[market] ?? []);
		return source.filter((item: QueueData) => item.orientation === format);
	};

	const filterByFormat = (targetFormat: Orientation | null = null) => {
		const market = selectedMarket ?? 'default';
		const source = queue[market] ?? [];
		return source.filter((item: QueueData) => item.orientation === targetFormat);
	};

	const getSelectedQueue = (seed: APIImageQueue | null = null) => {
		const market = selectedMarket ?? 'default';
		const source = seed ?? (queue[market] ?? []);
		const filteredItems = source.filter((item: QueueData) => item.orientation === format);

		if (showSelection) {
			return filteredItems.filter((item: QueueData) => selectedItems.includes(item.id));
		} else {
			return filteredItems;
		}
	};

	const getQueueByOrientation = () => {
		if (Object.keys(queue).length === 0) return { 'default': [] };

		const market = selectedMarket ?? 'default';
		const source = queue[market] ?? [];

		const acc: Record<string, QueueData[]> = { 'noUri': [] };

		source.forEach(item => {
			if (!acc[item.orientation]) {
				acc[item.orientation] = [];
			}
			if (item.uri) {
				acc[item.orientation].push(item);
			} else {
				acc['noUri'].push(item);
			}
		});

		return acc;
	};


	const updateQuantity = (id: string, quantity: ISize) => {
		if (!id) return;
		const targetMarket = selectedMarket ?? 'default';

		setQueue(prevState => {
			const market = prevState[targetMarket] ?? [];
			const updatedMarket = market.map(item => item.id === id ? { ...item, ...quantity } : item);
			return { ...prevState, [targetMarket]: updatedMarket };
		});
	};

	const removeItem = (id: string) => {
		if (!id || !selectedMarket) return;
		removeIds([id]);
	}

	const removeIds = async (ids: string[]) => {
		if (!ids || !ids.length) return;

		const targetMarket = getTargetMarket();
		if (!targetMarket) return;

		setQueue(prevState => {
			const market = prevState[targetMarket] || queue.default;
			const updatedMarket = market.filter(item => !ids.includes(item.id));
			return { ...prevState, [targetMarket]: updatedMarket };
		});

		setIsFetchingList(true);

		const api = new Api('queue', 'r');
		const request = api.request(member, { ids });
		const response = await api.post(request);

		if (response.success) {
			const { count } = response.data;
			const message = count === 1 ? `${count} foi removido da fila atual.`
				: `${count} itens foram removidos da fila atual.`;
			Notify.Success(message);
			fetchQueueItems();
		}
		else setIsFetchingList(false);
	}

	const clearQueue = () => {
		const targetMarket = getTargetMarket();
		if (!targetMarket) return;
		const targetQueue = queue[targetMarket] || queue.default;
		// Filtra por orientação.
		if (targetQueue) {
			const list = targetQueue.filter((item: QueueData) => item.orientation === format);
			const ids: string[] = list.map((item: QueueData) => item.id);
			removeIds(ids);
		}
	}

	const getTargetMarket = (): EntityID => {
		const targetMarket = markets ? selectedMarket : member.marketID;
		if (!targetMarket) {
			Notify.error("Não conseguimos confirmar a loja");
			return null;
		}
		return targetMarket;
	}

	function count(format: Orientation): number {
		return filterByFormat(format)
			.reduce((accumulator, currentValue) => (accumulator + 1), 0);
	}

	return (
		<QueueContext.Provider
			value={{
				areAllValuesFalse,
				queueCount,
				addToQueue,
				addToQueuePromise,
				addImageToQueue,
				getFilteredQueue,
				getSelectedQueue,
				getQueueByOrientation,
				queue,
				setQueue,
				filteredQueue,
				filterByFormat,
				margin,
				setMargin,
				papers,
				setPapers,
				format,
				setFormat,
				updateQuantity,
				removeItem,
				removeIds,
				clearQueue,
				fetchQueueItems,
				isFetchingList,
				setIsFetchingList,
				pdfModalVisible,
				setPDFModalVisibility,
				selectedMarket,
				setSelectedMarket,
				storeDetails,
				selectedItems,
				setSelectedItems,
				showSelection,
				setShowSelection,
				count,
				allowedFormats,
				setAllowedFormats,
				allowedOrientations,
				setAllowedOrientations,
				fullPages,
				setFullPages,
				errorAddImageToQueue
			}}
		>
			{children}
		</QueueContext.Provider>
	);
};

export const useQueue = (): QueueContextData => {
	const context = useContext(QueueContext);

	if (!context) {
		throw new Error(
			"useQueue must be used within an QueueProvider"
		);
	}

	return context;
};

function dateToString(date: string): string {
	const isoDate = date.replace(' ', 'T');
	const dateObject = DateTime.fromISO(isoDate, { zone: 'Europe/Belgrade' });
	return dateObject
		.setZone('America/Sao_Paulo')
		.toLocaleString(DateTime.DATETIME_SHORT);
}
